import OneSignal from 'react-onesignal';
import { Store } from './services/store';

export default async function runOneSignal() {
    await OneSignal.init({
         appId: '5437b61b-7e6c-4a7f-9ce6-8cbfe9ae6059', // one signal Prod
        // appId: '6499f120-48b8-4310-8d6d-21d36c180e79', // OneSignal WebTest
        // appId: 'a83851c1-40f0-49d7-8b5e-d926d29d5fa7', // OneSignal acne serveur
        safari_web_id: "web.reach.app",
        notifyButton: {
          enable: true,
        },
        allowLocalhostAsSecureOrigin: true
    });
    window.OneSignal = window.OneSignal || [];
}

//https://www.acne.dgitech.fr